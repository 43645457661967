import { VerificationStepType } from "../../types/verification/steps";
import {NextButtonAtom} from '../../atoms/NextButton';
import {ChevronRightIcon} from '@heroicons/react/24/solid';

export const VideoLivenessTemplate = ({
  handleStepChange,
  handlePreviousStep,
  handleNextStep
}: {
  handleStepChange: (step: keyof VerificationStepType) => void;
  handlePreviousStep: () => void;
  handleNextStep: any
}) => {

    const handleNextButtonClick = async () => {
        try {
            //await handleFileUpload();
            handleStepChange("stepFour"); // Change to the appropriate step ID
        } catch (error) {
            console.log("Failed to upload ID card");
            window.alert("Failed to upload ID card. Please try again."); // Show an alert
        }
    };

  return (
    <main className="flex flex-col gap-4 border-2 min-h-[35rem] max-h-[35rem] h-[35rem] bg-neutral-100 border-[#ccc] flex-auto w-full px-6 py-10 mx-auto max-w-7xl lg:px-8">
      <h2 className="text-lg font-bold">Video Liveness</h2>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat..
      </p>
        <div className="md:w-1/3 w-2/3 mx-auto">
            <NextButtonAtom
                text="Next"
                Icon={ChevronRightIcon}
                onClick={handleNextButtonClick}
            />
        </div>
      <div className="flex justify-center">
        <p
          className="text-sm underline cursor-pointer underline-offset-4 hover:text-slate-600"
          onClick={handlePreviousStep}
        >
          Back
        </p>
      </div>
    </main>
  );
};
