import { ChangeEvent, ForwardRefExoticComponent } from "react";

export const InputWithIconAtom = ({
  label,
  name,
  value,
  handleInputChange,
  Icon,
}: {
  label: string;
  name: string;
  value: string;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  Icon: ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  return (
    <div>
      <div className="relative rounded-md shadow-sm ">
        <label htmlFor={name} className="sr-only">
          {label}
        </label>
        <input
          id={name}
          name={name}
          type={name}
          required
          value={value}
          onChange={handleInputChange}
          className="block w-full pl-3 rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
          placeholder={label}
        />
        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none right-2">
          <Icon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};
