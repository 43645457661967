
import { VerificationStepType } from "../types/verification/steps";
import { useState, useEffect, Key } from "react";
import axios from "axios";
import { BASEURL } from "../constants";
// import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Alert from "@mui/material/Alert";
import { useQuery } from "@tanstack/react-query";
import { useServiceLogout } from "../helpers/auth";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { result } from "lodash";
import {JobReportItem} from '../atoms/JobReportItem';



export const AdminDashboardPage = () => {

  interface Judge {
    first_name: any;
    id: any;
    name: string;
    email: string;
    award_category: string;
    needs_workplace_verified: boolean,
    registered_at: string | number;
    approval_state: string;
    judge: string;
    report: {
      "work_proof": {},
      "id_document_authentication": {
        "status": string,
        "validation_state": string,
        "cached": string
      },
      "liveness": {
        "status": string,
        "validation_state": string,
        "cached": string
      },
      "face_matching": {
        "status": string,
        "validation_state": string,
        "cached": string
      },
      "forensic_analysis": {
        "status": string,
        "validation_state": string,
        "cached": string
      }
    }

  }
  type UserData = { name: string; email: string; award_category: string; registered_at: string; approval_state: string; judge: string; };
  const [steps, setSteps] = useState<VerificationStepType>({ stepOne: true, stepTwo: false, stepThree: false, stepFour: false, });
  const [approvalState, setApprovalState] = useState<"approved" | "declined" | "">("");
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Judge | null>(null);
  const [displayedJudges, setDisplayedJudges] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const logout = useServiceLogout();


  // API DATA

  const resetSensityData = async (judge: Judge) => {
    try {
      await axios.patch(
        `${BASEURL}/v1/reports/reset_sensity_data/${judge.id}`,
        {
          // Pass any necessary data for the reset operation if required by the API
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // console.log(selectedUser);

      // Show a success alert
      setResetDataAlert({ type: "success", message: "Sensity data reset successfully." });

      // Close the alert after 3 seconds
      setTimeout(() => {
        setResetDataAlert({ type: "success", message: null });
      }, 3000);
      window.location.reload();
    } catch (error) {
      // Handle errors if the API request fails
      console.error("Error resetting Sensity data:", error);

      // Show an error alert
      setResetDataAlert({ type: "error", message: "Error resetting Sensity data. Please try again." });

      // Close the error alert after 3 seconds
      setTimeout(() => {
        setResetDataAlert({ type: "error", message: null });
      }, 3000);
    }
  };

  const fetchData = async (judge: Judge) => {
    try {
      // Fetch user data for the selected user based on the user ID
      const userDataResponse = await axios.get(`${BASEURL}/v1/judges/${judge.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const jobResultsResponse = await axios.get(`${BASEURL}/v1/judges/${judge.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setUserData(userDataResponse.data);
      setJobResults(jobResultsResponse.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const handleDelete = async (judge: Judge) => {
    try {
      await axios.delete(
        `${BASEURL}/v1/judges/${judge.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )

      // Update the approval state
      setAlert({ type: "success", message: `Judge deletion successfully.` });

      // Close the alert after 3 seconds
      setTimeout(() => {
        setAlert({ type: "success", message: null });
      }, 3000);
      window.location.reload();
    } catch (error) {
      // Show error alert
      setAlert({ type: "error", message: "Error deleting. Please try again." });

      // Close the error alert after 3 seconds
      setTimeout(() => {
        setAlert({ type: "error", message: null });
      }, 3000);

      console.error("Error deleting", error);
    }
  }

  const updateApprovalState = async (newState: "approved" | "declined", judge: Judge) => {
    try {
      await axios.patch(
        `${BASEURL}/v1/judges/${judge.id}`,
        { approval_state: newState },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )

      // Update the approval state and show success alert
      setApprovalState(newState);
      setAlert({ type: "success", message: `Judge ${newState} successfully.` });

      // Close the alert after 3 seconds
      setTimeout(() => {
        setAlert({ type: "success", message: null });
      }, 3000);
      window.location.reload();
    } catch (error) {
      // Show error alert
      setAlert({ type: "error", message: "Error updating approval state. Please try again." });

      // Close the error alert after 3 seconds
      setTimeout(() => {
        setAlert({ type: "error", message: null });
      }, 3000);

      console.error("Error updating approval state:", error);
    }
  };


  const { isLoading: judgesLoading, isError: judgesError, data: judgesData, } = useQuery(["judges"], async () => {
    try {
      const response = await axios.get(`${BASEURL}/v1/judges`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching judges data:", error);
      throw error;
    }
  });



  // POPUP - DOCUMENTATION



  // Function to close the document modal
  const closeDocumentModal = () => {
    setIsDocumentModalOpen(false);
  };

  const openDocumentModal = () => {
    setIsDocumentModalOpen(true);
  };



  // Additional

  const [alert, setAlert] = useState<{ type: "success" | "error"; message: string | null }>({ type: "success", message: null, });
  const [resetDataAlert, setResetDataAlert] = useState<{ type: "success" | "error"; message: string | null }>({ type: "success", message: null, });
  const [userData, setUserData] = useState<{ award_category: string; approval_state: string; registered_at: string; }[]>([]);
  const [userImageData, setUserImageData] = useState<{ award_category: string; approval_state: string; registered_at: string; }[]>([]);
  const [jobResults, setJobResults] = useState<{
    report: any; face_matching: any; liveness: any; forensic_analysis: any; id_document_authentication: any; reports: any;
    name: string; email: string; award_category: string; approval_state: string; registered_at: string; needs_workplace_verified: boolean;
  }[]>([]);

  // FUNCTIONS


  const handleUserClick = (judge: Judge | null) => {
    if (judge) {
      // Create a Judge object to match the expected type
      const selectedJudge: Judge = judge;
      selectedJudge.registered_at =
          typeof judge.registered_at === "number"
          ? judge.registered_at.toString()
          : judge.registered_at;
      setSelectedUser(selectedJudge);
      setIsModalOpen(true);

      // Call fetchData with the user's ID
      fetchData(selectedJudge);
    }
  };


  function handleStepChange(step: keyof VerificationStepType): void {
    setSteps((prevState) => {
      const newState: VerificationStepType = { ...prevState };

      let stepChanged = false;
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && step === key) {
          newState[key as keyof VerificationStepType] = true;
          stepChanged = true;
        } else if (stepChanged) {
          newState[key as keyof VerificationStepType] = false;
        } else {
          newState[key as keyof VerificationStepType] = true;
        }
      }

      return newState;
    });
  }

  const handleCloseAlert = () => {
    setAlert({ ...alert, message: null });
  };


  // SORTING


  // Define state variables to track sorting direction for each column
  const [nameSortOrder, setNameSortOrder] = useState<"asc" | "desc">("asc");
  const [emailSortOrder, setEmailSortOrder] = useState<"asc" | "desc">("asc");
  const [awardSortOrder, setAwardSortOrder] = useState<"asc" | "desc">("asc");
  const [dateSortOrder, setDateSortOrder] = useState<"asc" | "desc">("asc");
  const [verifySortOrder, setVerifySortOrder] = useState<"asc" | "desc">("asc");

  // Define sorting functions for each column
  const sortByName = (data: Judge[], direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      const nameA = a.first_name.toLowerCase();
      const nameB = b.first_name.toLowerCase();
      return direction === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
  };

  const sortByEmail = (data: Judge[], direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      const emailA = a.email.toLowerCase();
      const emailB = b.email.toLowerCase();
      return direction === "asc" ? emailA.localeCompare(emailB) : emailB.localeCompare(emailA);
    });
  };

  const sortByAward = (data: Judge[], direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      const awardA = a.award_category.toLowerCase();
      const awardB = b.award_category.toLowerCase();
      return direction === "asc" ? awardA.localeCompare(awardB) : awardB.localeCompare(awardA);
    });
  };

  const sortByDate = (data: Judge[], direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a.registered_at).getTime();
      const dateB = new Date(b.registered_at).getTime();
      return direction === "asc" ? dateA - dateB : dateB - dateA;
    });
  };


  const sortByVerifcation = (data: Judge[], direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      const verifyA = a.approval_state.toLowerCase();;
      const verifyB = b.approval_state.toLowerCase();;
      return direction === "asc" ? verifyA.localeCompare(verifyB) : verifyB.localeCompare(verifyA);
    });
  };

  // Handle sorting when the column headers are clicked
  const handleSortByName = () => {
    setDisplayedJudges(sortByName(displayedJudges, nameSortOrder));
    setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByEmail = () => {
    setDisplayedJudges(sortByEmail(displayedJudges, emailSortOrder));
    setEmailSortOrder(emailSortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByAward = () => {
    setDisplayedJudges(sortByAward(displayedJudges, awardSortOrder));
    setAwardSortOrder(awardSortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByDate = () => {
    setDisplayedJudges(sortByDate(displayedJudges, dateSortOrder));
    setDateSortOrder(dateSortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByVerifcation = () => {
    setDisplayedJudges(sortByVerifcation(displayedJudges, verifySortOrder));
    setVerifySortOrder(verifySortOrder === "asc" ? "desc" : "asc");
  };


  // REACT - UseEffect


  useEffect(() => {
    if (searchQuery.trim() === "") {
      setDisplayedJudges(judgesData || []);
    } else {
      const filtered = judgesData
        ? judgesData.filter((judge: any) =>
          (judge.email && judge.email.toLowerCase().includes(searchQuery.toLowerCase()))
              ||(judge.first_name && judge.first_name.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        : [];
      setDisplayedJudges(filtered);
    }

    // Check if there's a selected user before calling fetchData
    if (selectedUser) {
      fetchData(selectedUser); // Pass the selected user to fetchData
    }



  }, [judgesData, searchQuery, selectedUser, alert]);


  // POPUP - Judge Details



  const UserModal = ({
    user,
    onClose,
  }: {
    user: Judge; // Change the type from UserData to Judge
    onClose: () => void;
  }) => {
    return (
      <div id="JudgeDetailsPopup" className="modal-content">
        <div className="tableContainer flex flex-col bg-[#fff] p-8 border-2 border-[#ccc]">
          <Snackbar
            open={Boolean(alert.message)}
            autoHideDuration={3000} // Set the duration in milliseconds (3 seconds in this case)
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseAlert}
              severity={alert.type}
            >
              {alert.message}
            </MuiAlert>
          </Snackbar>
          {/* HEADER */}
          <div className="py-4 flex justify-between items-center w-full">
            <h1 className="text-3xl font-bold">{user.name}</h1>
            <div className="">
              <button
                className="btnStevie p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px]"
                onClick={() => updateApprovalState("approved", selectedUser!)}
              >
                Approve
              </button>
              <button
                className="btnStevie p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px] ml-4"
                onClick={() => updateApprovalState("declined", selectedUser!)}
              >
                Decline
              </button>
            </div>
          </div>
          <div className="flex flex-col p-8 h-[450px] overflow-auto">

            {/* TABLE CONTENT */}
            <div className="wrapper w-full flex flex-row h-auto">
              {/* LEFT SECTION */}
              <div className="leftSection w-[50%] flex flex-col">
                <div className="tableWrapper flex flex-col">
                  <table className="">

                    <thead className="">
                      <tr>
                        <th className="py-2 px-4 text-left text-2xl">User Data</th>
                        <th className="py-2 px-4 text-left"></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="">
                  <table className=" mb-8">
                    <tbody className="">
                      <tr className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Award Type</td>
                        <td className="py-2 px-4">{user.award_category}</td>
                      </tr>
                    </tbody>
                    <tbody className="">
                      <tr className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Current Status</td>
                        <td className="py-2 px-4">{user.approval_state}</td>
                      </tr>
                    </tbody>
                    <tbody className="">
                      <tr className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Date Registered</td>
                        <td className="py-2 px-4">{user.registered_at}</td>
                      </tr>
                    </tbody>
                    <tbody className="">
                      <tr className="hover-bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Date Verified</td>
                        <td className="py-2 px-4">{user.registered_at}</td>
                      </tr>
                    </tbody>
                    <tbody className="">
                      <tr className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Judge submissions</td>
                        <td className="py-2 px-4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* RIGHT SECTION */}
              <div className="rightSection w-[50%] flex flex-col">
                <a
                  href={`mailto:${user.email}?subject=Subject%20Here&body=Body%20Here`}
                  className="btnStevie p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px] ml-4 mt-20"
                >
                  Send Email
                </a>
              </div>
            </div>
            {/* TABLE CONTENT */}
            <div className="wrapper w-full flex flex-row h-auto">
              {/* LEFT SECTION */}
              <div className="leftSection w-[50%] flex flex-col">
                <div className="tableWrapper flex flex-col">
                  <table className="">
                    <thead className="">
                      <tr>
                        <th className="py-2 px-4 text-left text-2xl">Job Results</th>
                        <th className="py-2 px-4 text-left"></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="">
                  <table className="mb-8">
                    <JobReportItem report_state={user.report.id_document_authentication.validation_state} title={'Identity Verification'}></JobReportItem>
                    <JobReportItem report_state={user.report.forensic_analysis.validation_state} title={'Forensic Analysis'}></JobReportItem>
                    <JobReportItem report_state={user.report.liveness.validation_state} title={'Liveness Check'}></JobReportItem>
                    <JobReportItem report_state={user.report.face_matching.validation_state} title={'Face Matching'}></JobReportItem>
                  </table>
                </div>
              </div>
              {/* RIGHT SECTION */}
              <div className="rightSection w-[50%] flex flex-col">
                <button
                  className=" btnStevie p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4"
                  onClick={() => resetSensityData(user)}
                >
                  Reset Sensity Data
                </button>
                {resetDataAlert.message && (
                  <Snackbar
                    open={Boolean(resetDataAlert.message)}
                    autoHideDuration={3000} // Set the duration in milliseconds (3 seconds in this case)
                    onClose={() => setResetDataAlert({ ...resetDataAlert, message: null })}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      onClose={() => setResetDataAlert({ ...resetDataAlert, message: null })}
                      severity={resetDataAlert.type}
                    >
                      {resetDataAlert.message}
                    </MuiAlert>
                  </Snackbar>
                )}
              </div>
            </div>
            {/* TABLE CONTENT */}
            {user.needs_workplace_verified && (<div className="wrapper w-full flex flex-row h-auto">
              {/* LEFT SECTION */}
              <div className="leftSection w-[50%] flex flex-col">
                <div className="tableWrapper flex flex-col">
                  <table className="">
                    <thead className="">
                      <tr>
                        <th className="py-2 px-4 text-left text-2xl">Work Verification Documents: </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              {/* RIGHT SECTION */}
              <div className="rightSection w-[50%] flex flex-col">
                <button className="btnStevie" onClick={openDocumentModal}>View Documents</button>

                {/* Render the document modal if isDocumentModalOpen is true */}
                {isDocumentModalOpen && (
                  <DocumentModal onClose={closeDocumentModal} judge={selectedUser!} />
                )}

              </div>
            </div>)}
          </div>
          <div className="rightSection w-[100%] flex flex-row justify-between mt-8">
            <button className="btnStevie p-4 bg-gray-200 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4 " onClick={onClose}>Back</button>
            <button className="btnStevie p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4 " onClick={() => { handleDelete(selectedUser!) }}>Delete</button>
          </div>
        </div>
      </div>
    );
  };


  // WORK VERIFCATION DOCUMENT

  interface Judge {
    id: any
    // Add other Judge properties here
  }

  interface DocumentModalProps {
    onClose: () => void;
    judge: Judge;
  }

  const DocumentModal: React.FC<DocumentModalProps> = ({ onClose, judge }) => {
    const [resetDataAlert, setResetDataAlert] = useState<{
      type: 'success' | 'error' | null;
      message: string | null;
    }>({ type: null, message: null });

    const [imageUrl, setImageUrl] = useState<string>(`${BASEURL}/v1/photos/judge/${judge.id}`);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


      function fetchImageAndConvertToBase64(url: string, headers: Record<string, string>) {
        return axios
          .get(url, {
            responseType: 'arraybuffer',
            headers: headers,
          })
          .then(response => Buffer.from(response.data, 'binary').toString('base64'))
          .catch(ex => {
            console.error(ex);
            throw ex;
          });
      }

      const fetchDocument = async (judge: Judge) => {
        try {
          setImageUrl( `${BASEURL}/v1/photos/judge/${judge.id}` );

          console.log("Image URL: " + imageUrl);

          // Add authorization headers
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          };

          // Fetch the image and convert it to base64
          const base64Image = await fetchImageAndConvertToBase64(imageUrl!, headers);

          // Show a success Snackbar
          setSnackbarMessage(judge.id);
          setSnackbarOpen(true);

          setTimeout(() => {
            setSnackbarOpen(false);
            setSnackbarMessage("");
          }, 3000);
        } catch (error) {
          // Handle errors if the API request fails
          console.error("No Document Found", error);
          console.log("No Documents Found ");

          // Show an error Snackbar
          setSnackbarMessage("Failed to fetch document");
          setSnackbarOpen(true);

          setTimeout(() => {
            setSnackbarOpen(false);
            setSnackbarMessage("");
          }, 3000);
        }
      };

      const handleFetchDocumentClick = () => {
        fetchDocument(judge);
      };

    return (
      <div className="modal-content bg-white border-2 border-[#ccc]">
        <div className="modal-header">
          <h2 className="text-3xl font-bold" id="modal-title">Work Verification Documents</h2>
          <IconButton
            aria-label="Close"
            onClick={onClose}
            sx={{ position: "absolute", top: 20, right: 20, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <button className="flex absolute bottom-8 right-8 bg-slate-500 text-white px-4 py-2 rounded-md" onClick={handleFetchDocumentClick}>Fetch Document</button>
        <div className="pt-12 flex justify-center items-center align-center">
        {imageUrl ? (
          <img src={imageUrl} alt="Document" />
        ) : (
          <div className="flex m-auto justify-center align-center">
          <p className="bg-red-200 px-4 py-2 rounded-md">No Document Found</p>
          </div>
        )}
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setSnackbarOpen(false);
            setSnackbarMessage("");
          }}
          message={snackbarMessage}
        />
      </div>
    );
  };





  return (
    <div className=" flex flex-col min-h-screen">
      <div className="h-[160px] w-full flex justify-center items-center bg-repeat " style={{ backgroundImage: "url('/main-head-bg.png')" }}>
        <div className="h-[160px] w-[1170px] pl-2  pt-5">
          <img src="/newermainsa-logo.png" />
        </div>
      </div>
      <div className="flex">
        <button className="flex absolute top-8 right-[375px] btnStevie " onClick={logout}>Logout</button>
      </div>
      <div className="h-[44px] w-full bg-black flex ">
        <div className="text-white w-[1170px] m-auto font-bold uppercase"> <span>  Admin Dashboard </span></div>
      </div>
      <div className=" w-[1170px] flex flex-row  mx-auto mt-8 gap-4">
        <div className="h-[200px] p-4 bg-[#303030] w-[262px]">
          {/* <h1 className="text-[#D5A800] text-[24px] font-bold mb-4 fontStevie">MENU</h1> */}
          <div className="flex flex-col  gap-4 text-[16px]  text-white ">
            <a className="hover:text-[#dddddd] hover:underline " href="/admin-dashboard">View Users</a>
            <a className="hover:text-[#dddddd] hover:underline " href="/send-invitation">Send Invitation</a>
            <a className="hover:text-[#dddddd] hover:underline " href="/">Return to Main Site</a>
          </div>
        </div>
        <div className="w-[60vw] h-auto p-8 border-[#ccc] border-2 " style={{ background: "#F0F0F0", }}>
          <div className="px-2 py-4 flex justify-between items-center w-full">
            <h1 className="text-3xl font-bold">Judge Applications</h1>
            <input
              type="search"
              className="px-4 py-2 border rounded-lg"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="w-full overflow-hidden" >
            <div className="w-full sticky top-0  " >
              <table className="w-full border-collapse table-fixed " >
                <thead className="underline">
                  <tr style={{ background: "#F0F0F000" }}>
                    <th
                      className="py-2 pl-2 text-left cursor-pointer"
                      onClick={handleSortByName}
                    >
                      Name
                      {nameSortOrder === "asc" ? " ▲" : " ▼"}
                    </th>
                    <th
                      className="py-2 px-0 text-left cursor-pointer"
                      onClick={handleSortByEmail}
                    >
                      Email
                      {emailSortOrder === "asc" ? " ▲" : " ▼"}
                    </th>
                    <th
                      className="py-2 px-0 text-left cursor-pointer"
                      onClick={handleSortByAward}
                    >
                      Award Group
                      {awardSortOrder === "asc" ? " ▲" : " ▼"}
                    </th>
                    <th
                      className="py-2 px-0 text-left cursor-pointer"
                      onClick={handleSortByDate}
                    >
                      Date
                      {dateSortOrder === "asc" ? " ▲" : " ▼"}
                    </th>
                    <th
                      className="py-2 px-0 text-left cursor-pointer"
                      onClick={handleSortByVerifcation}
                    >
                      Verifcation
                      {verifySortOrder === "asc" ? " ▲" : " ▼"}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="w-full overflow-auto overflow-hidden" style={{ background: "#F0F0F000" }}>
              <table className="w-full border-collapse table-fixed table overflow-auto  ">
                <tbody className="" style={{ background: "#F0F0F000" }}>
                  {judgesLoading ? (
                    <tr>
                      <td>Loading judges...</td>
                    </tr>
                  ) : judgesError ? (
                    <tr>
                      <td>Error loading judges data</td>
                    </tr>
                  ) : displayedJudges ? (
                    displayedJudges.map((judge: Judge, index: Key | null | undefined) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleUserClick(judge)}
                      >
                        <td className="py-2 px-0">{judge.first_name}</td>
                        <td className="py-2 px-0">{judge.email}</td>
                        <td className="py-2 px-0">{judge.award_category}</td>
                        <td className="py-2 px-0">{judge.registered_at}</td>
                        <td
                          className={`py-2 pl-20 ${judge.approval_state === "Verified"
                            ? "text-green-500"
                            : judge.approval_state === "Failed"
                              ? "text-red-500"
                              : judge.approval_state === "Error"
                                ? "text-gray-500"
                                : ""
                            }`}
                        >
                          {judge.approval_state}
                        </td>
                      </tr>
                    ))
                  ) : (
                    []
                  )}
                </tbody>
              </table>
            </div>
            {isModalOpen && selectedUser && (
              <UserModal
                user={selectedUser}
                onClose={() => setIsModalOpen(false)} // Close the modal by setting setIsModalOpen to false
              />
            )}
          </div>

        </div>
      </div>
    </div>
  );
}


