import { AdminLoginFormMolecule } from "../../molecules/AdminLoginForm";

export const AdminLoginTemplate = () => {
  return (
    <div className="">
          <div className="h-[160px] w-full flex justify-center items-center bg-repeat " style={{ backgroundImage: "url('/main-head-bg.png')" }}>
        <div className="h-[160px] w-[1170px] pl-2 justify-between pt-5 ">
          <img src="/newermainsa-logo.png" />
          
        </div>
        <div className="flex">
        <a href="/" className="flex absolute top-8 right-[375px] btnStevie ">Go Back</a>
      </div>
      </div>
      <div className="h-[44px] w-full bg-black flex ">
        <div className="text-white w-[1170px] m-auto font-bold uppercase"> <span>Adminstration Login</span></div>
        
      </div>
      <div className="w-full h-full mt-20  flex flex-col gap-6 items-center">
        <div className="p-8 w-full max-w-md space-y-8 bg-neutral-100 border-[#ccc] border-2">
          <AdminLoginFormMolecule />
        </div>
        <div className="flex justify-center w-full">
        {/* <a href="/admin-dashboard">Admin Dashboard</a> */}
        </div>
      </div>
      </div>
  );
};
