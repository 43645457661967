import { ChangeEvent, FormEvent, useState } from "react";
import { EyeIcon, EyeSlashIcon, UserIcon } from "@heroicons/react/24/solid";

import { BASEURL } from "../constants";
import { InputWithIconAtom } from "../atoms/InputWithIcon";
import { InputsData } from "../types/login";
import { PassWordInputAtom } from "../atoms/PasswordInput";
import { SubmitButtonAtom } from "../atoms/SubmitButton";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useServiceLogin } from "../helpers/auth";

export const LoginFormMolecule = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputsData, setInputsData] = useState<InputsData>({
    email: "",
    password: "",
  });

  const login = useServiceLogin();

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loginMutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      axios.post(`${BASEURL}/auth/login/judge`, { username: email, password }),
    {
      onSuccess: (res) => {
        login(
          res.data.access_token,
          res.data.email,
          res.data.needs_workplace_verified
        );
      },
      onError: (error) => {
        alert("Login failed. Please check your credentials.");
      },
    }
  );

  // const googleLoginMutation = useMutation(
  //   ({ credential }: { credential: string }) =>
  //     axios.post(`${BASEURL}/auth/google`, { credential: credential }),
  //   {
  //     onSuccess: (res) => {
  //       login(
  //         res.data.access_token,
  //         res.data.email,
  //         res.data.needs_workplace_verified
  //       );
  //     },
  //   }
  // );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loginMutation.isLoading) {
      loginMutation.mutate(inputsData);
    }
  };

  // const handleGoogleLogin = async (response: any) => {
  //   if (!googleLoginMutation.isLoading) {
  //     googleLoginMutation.mutate({ credential: response.credential });
  //   }
  // };

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      {/* <div className="flex justify-center">
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => console.log("error")}
              />
            </div> */}
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="space-y-6 rounded-md shadow-sm">
        <InputWithIconAtom
          label="Email"
          name="email"
          value={inputsData.email}
          handleInputChange={handleInputChange}
          Icon={UserIcon}
        />
        Your email address (the one to which the email you received was sent)
        <PassWordInputAtom
          label="Password"
          name="password"
          showPassword={showPassword}
          value={inputsData.password}
          handleInputChange={handleInputChange}
          handlePasswordVisibility={handlePasswordVisibility}
          Icon={showPassword ? EyeSlashIcon : EyeIcon}
        />
        The password supplied to you in the email you received
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember_me"
            name="remember_me"
            type="checkbox"
            className="flex cursor-pointer items-center justify-center w-4 h-4 border rounded-full focus:ring-gray-600 text-gray-500"
            defaultValue="true"
          />
          <label
            htmlFor="remember_me"
            className="block ml-2 cursor-pointer text-sm font-medium text-slate-600 hover:text-slate-500"
          >
            Remember me
          </label>
        </div>
        {/* <div>
          <Link
            to="/forgot-password"
            className="font-medium text-slate-600 text-sm hover:text-slate-500"
          >
            Forgot password?
          </Link>
        </div> */}
      </div>
      <div>
        <SubmitButtonAtom label="Login" disabled={loginMutation.isLoading} />
      </div>
    </form>
  );
};
