export const SubmitButtonAtom = ({
  label,
  disabled,
}: {
  label: string;
  disabled: boolean;
}) => {
  return (
    <button
      type="submit"
      className="btnStevie relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-zinc-400 rounded-lg hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
      disabled={disabled}
    >
      {label}
    </button>
  );
};
