import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";

export const NextButtonAtom = ({
  text,
  Icon,
  onClick,
  disabled,
}: {
  text: string;
  Icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className="relative">
    <button
      type="button"
      className={` btnStevie3  inline-flex w-[100px] justify-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${
        disabled ? 'bg-black' : 'bg-gray-600 hover:bg-gray-700'
      } focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        disabled ? 'focus:ring-black' : 'focus:ring-gray-600'
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      <Icon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
    </button>
    </div>
  );
};
