
import { VerificationStepType } from "../types/verification/steps";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASEURL } from "../constants";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';



export const JudgeDetailsPage = () => {
  const [steps, setSteps] = useState<VerificationStepType>({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvalState, setApprovalState] = useState<"approved" | "declined" | "">("");
  const [alert, setAlert] = useState<{ type: "success" | "error"; message: string | null }>({
    type: "success",
    message: null,
  });

  const [userData, setUserData] = useState<{
    award_category: string;
    approval_state: string;
    registered_at: string;
}[]>([]);

  const [jobResults, setJobResults] = useState<{
    report: any;
    face_matching: any;
    liveness: any;
    forensic_analysis: any
    id_document_authentication: any;
    reports: any;
    name: string;
    email: string;
    award_category: string;
    approval_state: string;
    registered_at: string;
  }[]>([]);

  const fetchData = async () => {
    try {
      const userDataResponse = await axios.get(`${BASEURL}/v1/judges`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });


      const jobResultsResponse = await axios.get(`${BASEURL}/v1/judges`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });



      setUserData(userDataResponse.data);
      setJobResults(jobResultsResponse.data);
      // console.log(jobResults);
      // console.log(userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function handleStepChange(step: keyof VerificationStepType): void {
    setSteps((prevState) => {
      const newState: VerificationStepType = { ...prevState };

      let stepChanged = false;
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && step === key) {
          newState[key as keyof VerificationStepType] = true;
          stepChanged = true;
        } else if (stepChanged) {
          newState[key as keyof VerificationStepType] = false;
        } else {
          newState[key as keyof VerificationStepType] = true;
        }
      }

      return newState;
    });
  }


  const updateApprovalState = async (newState: "approved" | "declined") => {
    try {
      await axios.patch(
        `${BASEURL}/v1/judges`,
        { approval_state: newState },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // Update the approval state and show success alert
      setApprovalState(newState);
      setAlert({ type: "success", message: `Judge ${newState}` });
    } catch (error) {
      // Show error alert
      setAlert({ type: "error", message: "Error updating approval state. Please try again." });
      console.error("Error updating approval state:", error);
    }
  };


  return (
    <div className="flex flex-col justify-center min-h-screen">
      <div className="flex flex-col md:grid m-auto my-10 px-20 grid-cols-3 gap-4">
        <div className="flex absolute top-10 left-20 text-3xl">Administration Dashboard</div>
        {/* SIDEBAR  */}
        <div className="flex flex-col gap-4 text-2xl underline">
          <a href="/admin-dashboard">View Users</a>
          <a href="/send-invitations">Send Invitations</a>
          <a href="/">Return to Main Site</a>
        </div>


        {/* TABLE CONTAINER */}
        <div className="tableContainer flex flex-col p-8 w-[50vw] bg-[#eeeeee] rounded-3xl border-4 border-black" >
        {alert.message && (
                <Alert severity={alert.type === "success" ? "success" : "error"}>{alert.message}</Alert>
              )}
          {/* HEADER */}
          <div className="py-4 flex justify-between items-center w-full">
            <h1 className="text-3xl font-bold">Username01</h1>
            <div className="">

            <button
                className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px]"
                onClick={() => updateApprovalState("approved")}
              >
                Approve
              </button>
              <button
                className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px] ml-4"
                onClick={() => updateApprovalState("declined")}
              >
                Decline
              </button>

            </div>
          </div>
          <div className="tableContainer flex flex-col p-8 h-[450px] overflow-auto" >
          {/* TABLE CONTENT */}
          <div className="wrapper w-full flex flex-row h-auto">

            {/* LEFT SECTION */}
            <div className="leftSection w-[50%] flex flex-col">
              <div className="tableWrapper flex flex-col">
                <table className="" >
                  <thead className="">
                    <tr >
                      <th className="py-2 px-4 text-left text-2xl" >User Data</th>
                      <th className="py-2 px-4 text-left"></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="">
                <table className=" mb-8">
                  <tbody className=""  >
                    {userData.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Award Type</td>
                        <td className="py-2 px-4">{judge.award_category}</td>
                      </tr>

                    ))}
                  </tbody>
                  <tbody className=""  >
                    {userData.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Current Status</td>
                        <td className="py-2 px-4">{judge.approval_state}</td>
                      </tr>

                    ))}
                  </tbody>
                  <tbody className=""  >
                    {userData.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Date Registered</td>
                        <td className="py-2 px-4">{judge.registered_at}</td>
                      </tr>

                    ))}
                  </tbody>
                  <tbody className=""  >
                    {userData.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Date Verified</td>
                        <td className="py-2 px-4">{judge.registered_at}</td>
                      </tr>

                    ))}
                  </tbody>
                  <tbody className=""  >
                    {userData.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Judge submissions</td>
                        <td className="py-2 px-4"></td>
                      </tr>

                    ))}
                  </tbody>

                </table>
              </div>
            </div>
            {/* RIGHT SECTION */}
            <div className="rightSection w-[50%] flex flex-col">
              <a
                href="mailto:info@stevie.com?subject=Subject%20Here&body=Body%20Here"
                className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[150px] ml-4 mt-20"
              >
                Send Email
              </a>
            </div>

          </div>

           {/* TABLE CONTENT */}
           <div className="wrapper w-full flex flex-row h-auto">

            {/* LEFT SECTION */}
            <div className="leftSection w-[50%] flex flex-col">
              <div className="tableWrapper flex flex-col">
                <table className="" >
                  <thead className="">
                    <tr >
                      <th className="py-2 px-4 text-left text-2xl" >Job Results</th>
                      <th className="py-2 px-4 text-left"></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="">
                <table className="mb-8">
                  <tbody className=""  >
                    {jobResults.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Identity Verification</td>
                        <td className={`py-2 px-4 ${judge.report.id_document_authentication.status === "Verified"
                          ? "text-green-500"
                          : judge.report.id_document_authentication.status === "Failed"
                          ? "text-red-500"
                          : judge.report.id_document_authentication.status === "Error"
                            ? "text-gray-500"
                            : ""
                          }`}>{judge.report.id_document_authentication.status}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className=""  >
                    {jobResults.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Forensic Analysis</td>
                        <td className={`py-2 px-4 ${judge.report.forensic_analysis.status === "Verified"
                          ? "text-green-500"
                          : judge.report.forensic_analysis.status === "Failed"
                          ? "text-red-500"
                          : judge.report.forensic_analysis.status === "Error"
                            ? "text-gray-500"
                            : ""
                          }`}>{judge.report.forensic_analysis.status}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className=""  >
                    {jobResults.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Liveness Check</td>
                        <td className={`py-2 px-4 ${judge.report.liveness.status === "Verified"
                          ? "text-green-500"
                          : judge.report.liveness.status  === "Failed"
                          ? "text-red-500"
                          : judge.report.liveness.status  === "Error"
                            ? "text-gray-500"
                            : ""
                          }`}>{judge.report.liveness.status }</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className=""  >
                    {jobResults.map((judge, index) => (
                      <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                        <td className="py-2 px-4">Face Matching</td>
                        <td className={`py-2 px-4 ${judge.report.face_matching.status  === "Verified"
                          ? "text-green-500"
                          : judge.report.face_matching.status === "Failed"
                          ? "text-red-500"
                          : judge.report.face_matching.status === "Error"
                            ? "text-gray-500"
                            : ""
                          }`}>{judge.report.face_matching.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* RIGHT SECTION */}
            <div className="rightSection w-[50%] flex flex-col">
              <button className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4 mt-20">Reset Sensity Data </button>
            </div>

            </div>

            {/* TABLE CONTENT */}
           <div className="wrapper w-full flex flex-row h-auto">

          {/* LEFT SECTION */}
          <div className="leftSection w-[50%] flex flex-col">
            <div className="tableWrapper flex flex-col">
              <table className="" >
                <thead className="">
                  <tr >
                    <th className="py-2 px-4 text-left text-2xl" >Work Verification Documents: </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          {/* RIGHT SECTION */}
          <div className="rightSection w-[50%] flex flex-col">
          <button
              className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4"
              onClick={openModal}
            >
              View Documents
            </button>
          </div>

          </div>
           </div>

          <div className="rightSection w-[100%] flex flex-row justify-between mt-8">
            <button className="p-4 bg-gray-200 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4 ">Back</button>
            <button className="p-4 bg-gray-100 border-2 border-[#cccccc] rounded-2xl w-[200px] ml-4 ">Delete</button>
          </div>

        </div>
      </div>


      <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="text-3xl font-bold" id="modal-title">Work Verification Documents</h2>
              <IconButton
                aria-label="Close"
                onClick={closeModal}
                sx={{ position: "absolute", top: 20, right: 20, color: "#fff" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* Modal content here */}
          </div>
        </Modal>

    </div>

  );
};
