import { ChangeEvent, useEffect, useState } from "react";
import {
  ChevronRightIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import { BASEURL } from "../../constants";
import { FileUploadStateType } from "../../types/verification/fileUpload";
import { NextButtonAtom } from "../../atoms/NextButton";
import { PreviewDocumentOrIcon } from "../../atoms/PreviewDocumentOrIcon";
import { UploadFileInputAtom } from "../../atoms/UploadFileInput";
import { VerificationStepType } from "../../types/verification/steps";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

export const WorkVerificationUploadTemplate = ({
  files,
  handleFileChange,
  handleStepChange,
}: {
  files: FileUploadStateType;
  handleFileChange: (
    name: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  handleStepChange: (step: keyof VerificationStepType) => void;
}) => {
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);

  const handleNextButtonClick = () => {
    setNextButtonClicked(true);
    handleFileUpload();
    handleStepChange("stepTwo"); // Change to the appropriate step ID
  };

  useEffect(() => {
    if (files.workPlaceFile.file && nextButtonClicked) {
      setDisableNextButton(false);
    }
  }, [files.workPlaceFile.file, nextButtonClicked]);

  useEffect(() => {
    if (localStorage.getItem("needs_workplace_verified") === "false") {
      handleStepChange("stepTwo");
    }
  });

  const uploadWorkPlaceFileMutation = useMutation(
    ({ file }: { file: File }) => {
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("type", "workplace");
      formData.append("fileName", file.name);

      return axios.post(`${BASEURL}/v1/photos/employment`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        setDisableNextButton(false);
        handleStepChange("stepTwo");
      },
      onError: () => {
        console.log("Failed to upload work proof");
        window.alert("Failed to upload work proof. Please try again."); // Show an alert
        setDisableNextButton(false); // Re-enable the button after the error
      },
    }
  );

  function handleFileUpload() {
    if (!disableNextButton && files.workPlaceFile.file) {
      setDisableNextButton(true);

      uploadWorkPlaceFileMutation.mutate({
        file: files.workPlaceFile.file!,
      });
    }
  }

  useEffect(() => {
    if (files.workPlaceFile.file) {
      setDisableNextButton(false);
    }
  }, [files.workPlaceFile.file]);

  return (
    <main className="flex flex-col gap-4 border-2 min-h-[35rem] max-h-[35rem] h-[35rem] bg-neutral-100 border-[#ccc] flex-auto w-full px-6 py-10 mx-auto max-w-7xl lg:px-8">
      <h2 className="text-lg font-bold">Upload Document to Verify Workplace</h2>
      <div className="grid gap-2 pl-4">
        <p className="text-sm">
          <b>
            We have determined that we must verify the legitimacy of your work
            organization.
          </b>
          <br />
          <br />
          Please upload an image of <u>one</u> of the following documentation:
          <br />
          <br />
          <ul>
            <li>
              *Documented proof of the organization’s incorporation or
              registration
            </li>
            <li>*Copy of the organization’s current business license</li>
          </ul>
          <br />
          .jpeg and .png images and scans only will be accepted:
          <br />
        </p>
      </div>
      <PreviewDocumentOrIcon
        preview={files.workPlaceFile.preview!}
        Icon={DocumentMagnifyingGlassIcon}
      />
      <div className="grid grid-cols-3">
        <div></div>
        <div className="justify-self-center">
          <UploadFileInputAtom
            placeholder="Upload Files"
            name="workPlaceFile"
            accept="image/*"
            handlePreview={handleFileChange}
          />
        </div>
        <div className="justify-self-end">
          {files.workPlaceFile.preview && (
            <NextButtonAtom
              text="Next"
              Icon={ChevronRightIcon}
              onClick={handleNextButtonClick}
              disabled={disableNextButton}
            />
          )}
        </div>
      </div>
    </main>
  );
};
