import { BrowserRouter, Route, Routes } from "react-router-dom";

import { GuardedRoute } from "./guardedRoute";
import { LoginPage } from "./pages/Login";
import { AdminLoginPage} from "./pages/AdminLogin"
import { NotFoundPage } from "./pages/404";
import { VerificationPage } from "./pages/Verification";
import { AdminDashboardPage } from "./pages/AdminDashboard";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy";
import { SendInvitationPage } from "./pages/SendInvitation";
import { JudgeDetailsPage } from "./pages/JudgeDetails";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* here the unprotected routes go */}
        <Route
          element={
            <GuardedRoute routeGuarded={false} redirectRoute="/verification" />
          }
        >
          <Route path="/" element={<LoginPage />} />
          <Route path="admin-login" element={<AdminLoginPage />} />
   
        </Route>

        {/* here the protected routes go */}
        <Route element={<GuardedRoute routeGuarded={true} redirectRoute="/" />}>
          <Route path="verification" element={<VerificationPage />} />
          <Route path="admin-dashboard" element={<AdminDashboardPage/>} />
          <Route path="send-invitation" element={<SendInvitationPage/>} />
          <Route path="judge-details" element={<JudgeDetailsPage/>} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
