import { ForwardRefExoticComponent } from "react";

export const PreviewDocumentOrIcon = ({
  preview,
  Icon,
}: {
  preview: string;
  Icon: ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  return (
    <div className="flex justify-center items-center m-auto">
      {preview ? (
        <img
          src={preview}
          alt="preview"
          className="w-80 h-64 object-cover rounded-md"
        />
      ) : (
        <Icon className="w-32 h-32 text-gray-400" />
      )}
    </div>
  );
};

export const PreviewPhotoOrIcon = ({
  preview,
  Icon,
}: {
  preview: string;
  Icon: ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  return (
    <div className="flex justify-center items-center m-auto">
      {preview ? (
        <img
          src={preview}
          alt="preview"
          className="w-[250px] h-[250px] "
        />
      ) : (
        <Icon className="w-32 h-32 text-gray-400" />
      )}
    </div>
  );
};
