import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  ChevronRightIcon,
  IdentificationIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

import { BASEURL } from "../../constants";
import { FileUploadStateType } from "../../types/verification/fileUpload";
import { ID_DOCUMENT_FOR_VERIFICATION } from "../../constants/verification";
import { NextButtonAtom } from "../../atoms/NextButton";
import { PreviewDocumentOrIcon, PreviewPhotoOrIcon } from "../../atoms/PreviewDocumentOrIcon";
import { UploadFileInputAtom, UploadPhotoInputAtom } from "../../atoms/UploadFileInput";
import { VerificationStepType } from "../../types/verification/steps";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import {base64toBlob} from '../../helpers/converFiles';
import Webcam from 'react-webcam';

export const IdVerificationUploadTemplate = ({
  handleStepChange,
  files,
  handleFileChange,
}: {
  handleStepChange: (step: keyof VerificationStepType) => void;
  files: FileUploadStateType;
  handleFileChange: (
    name: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>("stepOne");
  const opacityClass = "opacity-10";
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [isRightSectionCompleted, setIsRightSectionCompleted] =
    useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [photoDataUrl, setPhotoDataUrl] = useState<string | null>(null);
  const [isWebcamActive, setIsWebcamActive] = useState<boolean>(false);
  const [idCardPhoto, setIdCardPhoto] = useState<string | null>(null);
  const [isPhotoSubmitted, setIsPhotoSubmitted] = useState<boolean>(false);

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsWebcamActive(true);
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };
  const capturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      window.alert(imageSrc);
      setCapturedImage(imageSrc);
    }

    if (isWebcamActive && videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
        setPhotoDataUrl(dataUrl);
      }
    }
  };

  const uploadIdCardFileMutation = useMutation(
      ({ file }: { file: File }) => {
        const blob = base64toBlob(photoDataUrl!.split(",")[1], "image/jpeg");
        const aPersonImagefile = new File([blob], "captured-photo.jpg", {
          type: "image/jpeg",
        });

        const formData = new FormData();
        formData.append("id_file", file);
        formData.append("file", aPersonImagefile);
        formData.append("type", "id_card");
        formData.append("fileName", file.name);

        return axios.post(
            `${BASEURL}/v1/reports/upload/all_validations`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
        );
      },
      {
        onSuccess: () => {
          console.log("File uploaded successfully");
          setIsFileUploaded(true);
          setDisableNextButton(false);
          handleStepChange("stepThree");
        },
        onError: (e) => {
          console.log("Failed to upload ID card");
          window.alert(e);//"Failed to upload ID card. Please try again."
          setDisableNextButton(false);
        },
      }
  );

  const handleFileUpload = () => {
    if (!disableNextButton && files.idCardFile.file) {
      setDisableNextButton(true);

      uploadIdCardFileMutation.mutate({
        file: files.idCardFile.file!,
      });
    }
  };

  const handleNextButtonClick = async () => {
    setNextButtonClicked(true);

    try {
      await handleFileUpload();
      handleStepChange("stepThree");
    } catch (error) {
      console.log("Failed to upload ID card");
      window.alert("Failed to upload ID card. Please try again.");
      setDisableNextButton(false);
    }
  };

  useEffect(() => {
    if (files.idCardFile.file && nextButtonClicked) {
      setDisableNextButton(false);
    }
  }, [files.idCardFile.file, nextButtonClicked]);

  return (
      <main className="flex flex-col gap-4 border-2 h-auto bg-neutral-100 border-[#ccc] flex-auto w-full px-6 py-10 mx-auto max-w-7xl lg:px-8">
        <div className="flex flex-col">
          {/* Left Section for File Upload */}
          <h2 className="text-lg font-bold">
            Personal ID Verification
          </h2>
          <div className="flex flex-row pr-4  m-auto justify-center items-center">
            {currentStep === "stepOne" && (
                <div>
                  <div className="Container gap-4 w-full h-full flex-row flex">
                    <div className="List w-[50%] ">
                      <h2 className="text-lg font-bold">
                        Step 1
                      </h2>
                      Take a photograph of your unexpired government-issued ID.  The ID <u>must</u> feature your headshot photo. Acceptable forms of ID include
                      <ul className="pl-4">
                        {ID_DOCUMENT_FOR_VERIFICATION.map((document) => {
                          return (
                              <li key={document.id} className="text-sm list-disc">
                                {document.name}
                              </li>
                          );
                        })}
                      </ul>
                      <div className="">
                        <div className="justify-self-center w-[200px] mt-12 ">
                          <UploadFileInputAtom
                              placeholder="Upload Image"
                              name="idCardFile"
                              accept="image/*"
                              handlePreview={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Upload w-[50%] h-[280px]">
                      <PreviewDocumentOrIcon
                          preview={files.idCardFile.preview!}
                          Icon={IdentificationIcon}
                      />
                    </div>
                  </div>
                </div>
            )}
          </div>

          {/* Right Section for Face Matching */}
          <h2 className="text-lg font-bold">
            Step 2
          </h2>
          <div>Click the button to start your webcam.  Center your face on screen, then click the Capture Photo button.</div>
          <div className="flex flex-row pr-4 gap-4 m-auto  justify-center items-center">
            <div className="cameraFeed justify-center items-center flex flex-col">
              <div className="video-container w-[250px] mb-4 ">
                <video ref={videoRef} autoPlay playsInline muted />
              </div>
              <button className="btnStevie" onClick={startWebcam}>
                Start Webcam
              </button>
            </div>
            <div className="cameraFeed justify-center items-center flex flex-col">
              <div className="video-container w-[250px] mb-4 ">
                {photoDataUrl ?
                    <img src={photoDataUrl} alt="Start Webcam and Capture Selfie" />
                    :
                    <UserCircleIcon className="w-32 h-32 text-gray-400" />
                }
              </div>
              <button className="btnStevie2" onClick={capturePhoto}>
                Capture Photo
              </button>
            </div>

          </div>
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>
        <div className="flex w-full justify-between">
          <div className="relative">
            <p
                className="backStevie bg-black text-white cursor-pointer hover:text-slate-600"
                onClick={() => handleStepChange("stepOne")}>
              Back
            </p>
          </div>
          <div className="justify-self-end ">
            {files.idCardFile.preview && (
                <NextButtonAtom
                    text="Next"
                    Icon={ChevronRightIcon}
                    onClick={handleNextButtonClick}
                    disabled={disableNextButton}
                />
            )}
          </div>
        </div>
      </main>
  );
};

