import { ChangeEvent, useState } from "react";

import { FileUploadStateType } from "../../types/verification/fileUpload";
import { HandlePhotoAndVideoMatchingMolecule } from "../../molecules/HandlePhotoAndVideoMatching";
import { IdVerificationUploadTemplate } from "../../templates/verification/IdVerificationUpload";
import { VerificationPendingTemplate } from "../../templates/verification/VerificationPending";
import { VerificationStepType } from "../../types/verification/steps";
import { WorkVerificationUploadTemplate } from "../../templates/verification/WorkVerificationUpload";

export const DisplayVerificationStepOrganism = ({
  steps,
  handleStepChange,
}: {
  steps: {
    stepOne: boolean;
    stepTwo: boolean;
    stepThree: boolean;
    stepFour: boolean;
  };
  handleStepChange: (step: keyof VerificationStepType) => void;
}) => {
  const [files, setFiles] = useState<FileUploadStateType>({
    workPlaceFile: {
      preview: null,
      file: null,
    },
    idCardFile: {
      preview: null,
      file: null,
    },
    idCardPhoto: { 
      preview: null,
      file: null,
    },
  });

  function handleFileChange(
    name: string,
    event: ChangeEvent<HTMLInputElement>
  ) {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFiles((prevState) => {
          return {
            ...prevState,
            [name]: {
              preview: reader.result,
              file: file,
            },
          };
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFiles((prevState) => {
        return {
          ...prevState,
          [name]: {
            preview: null,
            file: null,
          },
        };
      });
    }
  }

  if (!steps) return null;

  if (steps.stepOne && !steps.stepTwo && !steps.stepThree && !steps.stepFour) {
    return (
      <WorkVerificationUploadTemplate
        files={files}
        handleFileChange={handleFileChange}
        handleStepChange={handleStepChange}
      />
    );
  }

  if (steps.stepOne && steps.stepTwo && !steps.stepThree && !steps.stepFour) {
    return (
      <IdVerificationUploadTemplate
        handleStepChange={handleStepChange}
        files={files}
        handleFileChange={handleFileChange}
      />
    );
  }

  if (steps.stepOne && steps.stepTwo && steps.stepThree && !steps.stepFour) {
    return (
      <HandlePhotoAndVideoMatchingMolecule
        handleStepChange={handleStepChange}
        idCardFile={files.idCardFile.file}
      />
    );
  }

  if (steps.stepOne && steps.stepTwo && steps.stepThree && steps.stepFour) {
    return <VerificationPendingTemplate />;
  }

  return null;
};
