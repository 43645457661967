import { useNavigate } from "react-router-dom";

export const useServiceLogin = () => {
  const navigate = useNavigate();

  const login = (token: string, user_name: string, needs_workplace_verified: string) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("user_name", user_name);
    localStorage.setItem("needs_workplace_verified", needs_workplace_verified);
    navigate("/verification");
  };

  return login;

};

export const useServiceAdminLogin = () => {
  const navigate = useNavigate();

  const adminLogin = (token: string) => {
    localStorage.setItem("access_token", token);
    navigate("/admin-dashboard");
  };

  return adminLogin;

};

export const useServiceLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_name");
    navigate("/");
  };

  return logout;
};
