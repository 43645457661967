import { Navigate, Outlet } from "react-router-dom";

import { isExpired } from "react-jwt";

export function GuardedRoute({
  routeGuarded = false,
  redirectRoute = "/",
}: {
  routeGuarded: boolean;
  redirectRoute: string;
}): JSX.Element {
  // Get JWT token from localStorage
  const token = localStorage.getItem("access_token");

  // Check if user is authenticated
  const isAuth = !isExpired(`${token}`);

  return (routeGuarded && isAuth) || (!routeGuarded && !isAuth) ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
}
