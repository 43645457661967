export const JobReportItem = ( { report_state, title }: { report_state: string, title: string } ) => {
    return (
        <tbody className="">
        <tr className="hover:bg-gray-200 cursor-pointer">
            <td className="py-2 px-4">{title}</td>
            <td
                className={`py-2 px-4 ${report_state === "verified"
                    ? "text-green-500"
                    : report_state === "failed_verification"
                        ? "text-red-500"
                        : ""
                }`}
            >
                {report_state}
            </td>
        </tr>
        </tbody>
    );
};
