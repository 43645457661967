import { NoticeAtom } from "../../atoms/Notice";
import { VERIFICATION_STEPS } from "../../constants/verification";
import { VerificationStepType } from "../../types/verification/steps";

export const StepsSideNavbarTemplate = ({
  steps,
}: {
  steps: VerificationStepType;
}) => {
  return (
    <div className="grid gap-6 grid-rows-[min-content]">
      <h2>Verification Dashboard</h2>
      <ul className="grid gap-2 grid-rows-[min-content] h-min">
        {VERIFICATION_STEPS.map((step) => {
          return (
            <li key={step.id} className="flex gap-2 h-min">
              <input
                type="checkbox"
                style={{color:"#D5A800", borderRadius: "50%", padding: ".8rem"}}
                name={step.name}
                id={step.name}
                disabled // Disable the checkbox
                checked={steps[step.stateName as keyof VerificationStepType]}
              />
              <label htmlFor={step.name} className="text-sm cursor-default">
                {step.name}
              </label>
            </li>
          );
        })}
      </ul>

       {/* <div className="self-end">
        <NoticeAtom
         title="Notice:"
          message="All personal documents will be deleted from this system on final verification."
           backgroundColor="bg-yellow-100"
           link="/privacy"
          linkText="See Privacy Policy"
       />
     </div> */}



    </div>
  );
};
