import { BASEURL } from "../constants";
import { DisplayEmailAtom } from "../atoms/DisplayEmail";
import { DisplayVerificationStepOrganism } from "../organisms/varification/DisplayVerificationStep";
import { StepsSideNavbarTemplate } from "../templates/verification/StepsSideNavbar";
import { VerificationStepType } from "../types/verification/steps";
import { WorkVerificationUploadTemplate } from "../templates/verification/WorkVerificationUpload";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useServiceLogout } from "../helpers/auth";
import { NoticeAtom } from "../atoms/Notice";

export const VerificationPage = () => {
  const [steps, setSteps] = useState<VerificationStepType>({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });

  const {
    isLoading: stepsLoading,
    isError: stepsError,
    data: stepsData,
  } = useQuery(["steps"], async () => {
    const request = await axios.get(`${BASEURL}/v1/status`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        // judge_id: 2, // the endpoint doesn`t work and as of right now I don`t know how to get the judge_id
      },
    });

    return request.data;
  });

  const logout = useServiceLogout();

  function handleStepChange(step: keyof VerificationStepType): void {
    setSteps((prevState) => {
      const newState: VerificationStepType = { ...prevState };

      let stepChanged = false;
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && step === key) {
          newState[key as keyof VerificationStepType] = true;
          stepChanged = true;
        } else if (stepChanged) {
          newState[key as keyof VerificationStepType] = false;
        } else {
          newState[key as keyof VerificationStepType] = true;
        }
      }

      return newState;
    });
  }



  return (
    <div className="flex flex-col min-h-screen">
      <div className="h-[160px] w-full flex justify-center items-center bg-repeat " style={{ backgroundImage: "url('/main-head-bg.png')" }}>
        <div className="h-[160px] w-[1170px] pl-2 justify-between pt-5 ">
          <img src="/newermainsa-logo.png" />
        </div>
      </div>
      <div className="flex">
        <button className="flex absolute top-8 right-[375px] btnStevie " onClick={logout}>Logout</button>
      </div>
      <div className="h-[44px] w-full bg-black flex ">
        <div className="text-white w-[1170px] m-auto font-bold uppercase"> <span>Dashboard</span></div>
      </div>

      {/* <div className="flex absolute top-8 left-20"><a href="/admin-login">Admin Login</a></div> */}
      <div className="flex flex-row  mt-8 gap-4  mx-auto w-[1170px]">
        <div className="flex flex-col h-[300px] p-4 bg-neutral-500 w-[350px] text-white">

        <DisplayEmailAtom email={localStorage.getItem("user_name")!} />
        <StepsSideNavbarTemplate
          steps={steps}
        // handleStepChange={handleStepChange}
        />
        <div className=" text-black mt-48">
        <NoticeAtom
          title="Notice:"
          message="All personal documents will be deleted from this system on final verification."
          backgroundColor="bg-yellow-100"
          link="/privacy"
          linkText="See Privacy Policy"
        />
      </div>
        </div>
        <DisplayVerificationStepOrganism
          steps={steps}
          handleStepChange={handleStepChange}
        />
      </div>
    </div>
  );
};
