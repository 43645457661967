import { ChangeEvent, FormEvent, useState } from "react";
import { EyeIcon, EyeSlashIcon, UserIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

import { BASEURL } from "../constants";
import { InputWithIconAtom } from "../atoms/InputWithIcon";
import { InputsData } from "../types/login";
import { PassWordInputAtom } from "../atoms/PasswordInput";
import { SubmitButtonAtom } from "../atoms/SubmitButton";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useServiceAdminLogin } from "../helpers/auth";

export const AdminLoginFormMolecule = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputsData, setInputsData] = useState<InputsData>({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const adminlogin = useServiceAdminLogin();

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const adminloginMutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      axios.post(`${BASEURL}/auth/login/admin`, { username: email, password }),
    {
      onSuccess: (res) => {
        
        adminlogin(res.data.access_token);
      },
      onError: (error) => {
        alert("Login failed. Please check your credentials.");
      },
    }
  );

  const googleLoginMutation = useMutation(
    ({ credential }: { credential: string }) =>
      axios.post(`${BASEURL}/auth/google`, { credential: credential }),
    {
      onSuccess: (res) => {
        adminlogin(res.data.access_token);
      },
    }
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!adminloginMutation.isLoading) {
      adminloginMutation.mutate(inputsData);
    }
  };

  const handleGoogleLogin = async (response: any) => {
    if (!googleLoginMutation.isLoading) {
      googleLoginMutation.mutate({ credential: response.credential });
    }
  };

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      {/* <div className="flex justify-center">
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => console.log("error")}
              />
            </div> */}
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="space-y-6 rounded-md shadow-sm">
        <InputWithIconAtom
          label="Email"
          name="email"
          value={inputsData.email}
          handleInputChange={handleInputChange}
          Icon={UserIcon}
        />
        <PassWordInputAtom
          label="Password"
          name="password"
          showPassword={showPassword}
          value={inputsData.password}
          handleInputChange={handleInputChange}
          handlePasswordVisibility={handlePasswordVisibility}
          Icon={showPassword ? EyeSlashIcon : EyeIcon}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember_me"
            name="remember_me"
            type="checkbox"
            className="flex cursor-pointer items-center justify-center w-4 h-4 border rounded-full focus:ring-gray-600 text-gray-500"
            defaultValue="true"
          />
          <label
            htmlFor="remember_me"
            className="block ml-2 cursor-pointer text-sm font-medium text-slate-600 hover:text-slate-500"
          >
            Remember me
          </label>
        </div>
        {/* <div>
          <Link
            to="/forgot-password"
            className="font-medium text-slate-600 text-sm hover:text-slate-500"
          >
            Forgot password?
          </Link>
        </div> */}
      </div>
      <div>
        <SubmitButtonAtom label="Admin Login" disabled={adminloginMutation.isLoading} />
      </div>
    </form>
  );
};
