
import { VerificationStepType } from "../types/verification/steps";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASEURL } from "../constants";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useServiceLogout } from "../helpers/auth";


export const SendInvitationPage = () => {
  const [steps, setSteps] = useState<VerificationStepType>({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });
  const [isChecked, setIsChecked] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [awardCategory, setAwardCategory] = useState("ABA Entrant"); // Default value, you can set it based on your needs
  const [alert, setAlert] = useState<{ type: "success" | "error"; message: string | null }>({
    type: "success", // Default type can be either "success" or "error"
    message: null,
  });

  const [emailValid, setEmailValid] = useState(true); // New state variable for email validity



  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Update email validity whenever the email value changes
    useEffect(() => {
      setEmailValid(emailRegex.test(email));
    }, [email]);

  const sendInvitation = async () => {
    if (!emailValid) {
      setAlert({ type: "error", message: "Invalid email address. Please enter a valid email." });
      return;
    }

    try {
      const response = await axios.post(
        `${BASEURL}/v1/invitations`,
        {
          name: name,
          email: email,
          award_category: awardCategory,
          needs_workplace_verified: isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // Handle success: Show success alert
      setAlert({ type: "success", message: "Invitation sent successfully!" });
    } catch (error) {
      // Handle error: Show error alert
      setAlert({ type: "error", message: "Error sending invitation. Please try again." });
      console.error("Error sending invitation:", error);
    }
  };

  const Alert = ({ type, message }: { type: string; message: string | null }) => {
    if (!message) {
      return null;
    }

    return (
      <div
        className={`p-4 rounded-md ${
          type === "success" ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
        }`}
      >
        {message}
      </div>
    );
  };

  function handleStepChange(step: keyof VerificationStepType): void {
    setSteps((prevState) => {
      const newState: VerificationStepType = { ...prevState };

      let stepChanged = false;
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && step === key) {
          newState[key as keyof VerificationStepType] = true;
          stepChanged = true;
        } else if (stepChanged) {
          newState[key as keyof VerificationStepType] = false;
        } else {
          newState[key as keyof VerificationStepType] = true;
        }
      }

      return newState;
    });
  }
  const logout = useServiceLogout();


  return (
    <div className=" flex flex-col min-h-screen">
    <div className="h-[160px] w-full flex justify-center items-center bg-repeat "  style={{ backgroundImage: "url('/main-head-bg.png')" }}>
        <div className="h-[160px] w-[1170px] pl-2  pt-5">
          <img src="/newermainsa-logo.png"/>
        </div>
      </div>
      <div className="flex">
        <button className="flex absolute top-8 right-[375px] btnStevie " onClick={logout}>Logout</button>
      </div>
      <div className="h-[44px] w-full bg-black flex ">
        <div className="text-white w-[1170px] m-auto font-bold uppercase"> <span>  Admin Dashboard </span></div>
      </div>
      <div className=" w-[1170px] flex flex-row  mx-auto mt-8 gap-4">
        <div className="h-[200px] p-4 bg-[#303030] w-[262px]">
          {/* <h1 className="text-[#D5A800] text-[24px] font-bold mb-4 fontStevie">MENU</h1> */}
          <div className="flex flex-col  gap-4 text-[16px]  text-white ">
            <a  className="hover:text-[#dddddd] hover:underline " href="/admin-dashboard">View Users</a>
            <a  className="hover:text-[#dddddd] hover:underline "  href="/send-invitation">Send Invitation</a>
            <a  className="hover:text-[#dddddd] hover:underline " href="/">Return to Main Site</a>
          </div>
        </div>
        <div className="w-[60vw] h-[450px] p-8 border-[#ccc] border-2 " style={{ background: "#F0F0F0", }}>
          <div className="px-8 py-4 flex justify-between items-center w-full">
            <h1 className="fontStevie text-3xl font-bold mb-4">Send Invitation</h1>
          </div>
          <div className="form">
          <div className="flex flex-row ">
            <label className="block mb-2 font-bold w-[200px] text-end pr-4">Name:</label>
            <input
              type="text"
              className="px-4 py-2 mb-4 border rounded-lg w-[450px]"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="flex flex-row">
          <label className="block mb-2 font-bold w-[200px] text-end pr-4">Email Address:</label>
          <input
            type="email"
            className="px-4 py-2 mb-4 border rounded-lg w-[450px]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          </div>

          <div className="flex flex-row">
          <label className="block mb-2 font-bold w-[200px] text-end pr-4">Award Group:</label>
          <select
            className="px-4 py-2 mb-4 border rounded-lg w-[250px]"
            value={awardCategory}
            onChange={(e) => setAwardCategory(e.target.value)}
          >
            <option value="ABA Entrant">ABA Entrant</option>
            <option value="ABA Judge">ABA Judge</option>
            <option value="APSA Entrant">APSA Entrant</option>
            <option value="APSA Judge">APSA Judge</option>
            <option value="GSA Entrant">GSA Entrant</option>
            <option value="GSA Judge">GSA Judge</option>
            <option value="IBA Entrant">IBA Entrant</option>
            <option value="IBA Judge">IBA Judge</option>
            <option value="MESA Entrant">MESA Entrant</option>
            <option value="MESA Judge">MESA Judge</option>
            <option value="SAGE Entrant">SAGE Entrant</option>
            <option value="SAGE Judge">SAGE Judge</option>
            <option value="SASCS Entrant">SASCS Entrant</option>
            <option value="SASCS Judge">SASCS Judge</option>
            <option value="SATE Entrant">SATE Entrant</option>
            <option value="SATE Judge">SATE Judge</option>
            <option value="SAWIB Entrant">SAWIB Entrant</option>
            <option value="SAWIB Judge">SAWIB Judge</option>
          </select>
          </div>

          <div className="flex flex-row items-center">
            <label className="block mb-2 font-bold w-[200px] text-end pr-4">Require Workplace Verification:</label>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={isChecked} // Replace isChecked with your own state variable
                  onChange={() => setIsChecked(!isChecked)} // Toggle the checked state
                />
              }
              label={isChecked ? "On" : "Off"}
            />
          </div>



          <Alert type={alert.type} message={alert.message} />

          <button
            className=" btnStevie mt-4 ml-20"
            onClick={sendInvitation}
          >
            Send Invite
          </button>


        </div>

        </div>
      </div>
    </div>
  );
};
