import classNames from "classnames";

export const NoticeAtom = ({
  message,
  title,
  backgroundColor = "bg-slate-400",
  link,
  linkText,
}: {
  message: string;
  title: string;
  backgroundColor?: string;
  link?: string;
  linkText?: string;
}) => {
  return (
    <div
      className={classNames(
        `grid rounded-md p-4 border-2 border-black gap-2 w-full h-full`,
        backgroundColor
      )}
    >
      <h2 className="text-sm">{title}</h2>
      <p className="text-xs">{message}</p>
      {link && (
        <a
          href={link}
          className="text-xs underline underline-offset-4 font-semibold leading-7 text-slate-600 hover:text-slate-500"
        >
          {linkText}
        </a>
      )}
    </div>
  );
};
