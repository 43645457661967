export const VerificationPendingTemplate = () => {
  return (
    <main className="flex flex-col gap-4 border-2 min-h-[35rem] max-h-[35rem] h-[35rem] bg-neutral-100 border-[#ccc] flex-auto w-full px-6 py-10 mx-auto max-w-7xl lg:px-8">
      <h2 className="text-lg font-bold">Thank you for your cooperation</h2>

      <p>All documentation and facial data has been successfully submitted.</p>
      <p>
        Stevie Awards Administration will contact you when verification is
        complete.
      </p>
    </main>
  );
};
