
export const VERIFICATION_STEPS: {
  id: number;
  name: string;
  stateName: string;
}[] = [
  {
    id: 1,
    name: "Work Verification",
    stateName: "stepOne",
  },
  {
    id: 2,
    name: "Scan ID Document",
    stateName: "stepTwo",
  },
  {
    id: 3,
    name: "Face Matching",
    stateName: "stepThree",
  },
  {
    id: 4,
    name: "Verification Pending",
    stateName: "stepFour",
  },
];

export const ID_DOCUMENT_FOR_VERIFICATION: { id: number; name: string }[] = [
  {
    id: 1,
    name: "Driver’s license",
  },
  {
    id: 2,
    name: "The photo page of your passport",
  },
  {
    id: 3,
    name: "Other valid government-issued ID",
  },
];
