import { DisplayEmailAtom } from "../atoms/DisplayEmail";
import { DisplayVerificationStepOrganism } from "../organisms/varification/DisplayVerificationStep";
import { StepsSideNavbarTemplate } from "../templates/verification/StepsSideNavbar";
import { VerificationStepType } from "../types/verification/steps";
import { WorkVerificationUploadTemplate } from "../templates/verification/WorkVerificationUpload";
import { useState } from "react";

export const PrivacyPolicyPage = () => {
  const [steps, setSteps] = useState<VerificationStepType>({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });

  function handleStepChange(step: keyof VerificationStepType): void {
    setSteps((prevState) => {
      const newState: VerificationStepType = { ...prevState };

      let stepChanged = false;
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && step === key) {
          newState[key as keyof VerificationStepType] = true;
          stepChanged = true;
        } else if (stepChanged) {
          newState[key as keyof VerificationStepType] = false;
        } else {
          newState[key as keyof VerificationStepType] = true;
        }
      }

      return newState;
    });
  }

  return (
    <div className="flex flex-col justify-center min-h-screen">
      <div className="flex flex-col md:grid m-auto my-10 px-20 grid-cols-3 gap-4">
        <div className="flex absolute top-10 left-20 text-3xl">
          Privacy Policy
        </div>
        {/* <StepsSideNavbarTemplate
          steps={steps}
          handleStepChange={handleStepChange}
        /> */}
        <div className="flex flex-col gap-4 text-2xl underline">
          {/* <a href="#">View Users</a> */}
          {/* <a href="#">Send Invitations</a> */}
          <a href="/">Return to Main Site</a>
        </div>
        <div className="w-[60vw] h-[600px] border-4 border-black py-4 px-4 rounded-2xl overflow-hidden">
          <div className="PolicyContainer  overflow-y-scroll h-[550px]">
            <h1 className="text-3xl mb-12">Our Commitment to Privacy</h1>
            <h3 className="text-xl mb-12"> Updated Aug 2023</h3>
            <p>
              Our Privacy Policy was developed as an extension of our commitment
              to combine the highest-quality products and services with the
              highest level of integrity in dealing with our clients and
              partners. The Policy is designed to assist you in understanding
              how we collect, use and safeguard the personal information you
              provide to us and to assist you in making informed decisions when
              using our site and our products and services. This statement will
              be continuously assessed against new technologies, business
              practices and our customers' needs.
            </p><br/>
          
{/* 
            <p className="flex flex-col gap-4">
              <a href="#Collect">What Information Do We Collect?</a>
              <a href="#Edit">
                How Can You Edit or Delete Your Personal Information?
              </a>
              <a href="#HowUse">
                How Do We Use the Information That You Provide to Us?
              </a>
              <a href="#Cookies">What Are Cookies, and How Do We Use Them?</a>
              <a href="#Retention">Data Retention and Erasure</a>
              <a href="#Notice">Notice of Breach of Security</a>
              <a href="#DoNotTrack">Do-Not-Track Procedures</a>
              <a href="#Protect">How Do We Protect Your Information?</a>
              <a href="#Disclose">
                Do We Disclose Information to Outside Parties?
              </a>
              <a href="#Changes">Changes to This Policy</a>
              <a href="#Consent">Your Consent</a>
              <a href="#Contact">How to Contact Us</a>
            </p><br/> */}

            <p>
              <strong>
                <a id=""></a>What Information Do We Collect?
              </strong>
            </p>

            <p>
              When you visit one of our websites you may provide us with two
              types of information: personal information you knowingly choose to
              disclose that is collected on an individual basis and website use
              information collected on an aggregate basis as you and others
              browse our sites.
            </p><br/>

            <p>
              <strong>1. Personal Information You Choose to Provide</strong>
            </p><br/>

            <p>
              <strong>Credit Card Information</strong>
            </p>

            <p>
              If you choose to purchase products or services from us, you may
              need to give personal information and authorization to obtain
              information from various credit services. For example, you may
              need to provide the following information:
            </p><br/>

            <ul>
              <li> - Name</li>
              <li> - Mailing address</li>
              <li> - Email address</li>
              <li> - Credit card number</li>
              <li> - Business phone number</li>
              <li> - Other personal information</li>
            </ul><br/>
            <p>
              See below for information about how we keep your credit card
              information secure.
            </p><br/>

            <p>
              <strong>Email Information</strong>
            </p>

            <p>
              In addition to providing the foregoing information to us, if you
              choose to correspond further with us through email, we may retain
              the content of your email messages together with your email
              address and our responses. We provide the same protections for
              these electronic communications that we employ in the maintenance
              of information received by mail and telephone.
            </p><br/>

            <p>
              <strong>2. Website Use Information</strong>
            </p><br/>

            <p>
              Similar to other commercial websites, our websites utilize a
              standard technology called "cookies" (see explanation below) and
              web server logs to collect information about how our websites are
              used. Information gathered through cookies and web server logs may
              include the date and time of visits, the pages viewed, time spent
              at our site(s), the IP address of your internet service provider,
              and the websites visited just before and just after our sites.
              This information is collected on an aggregate basis. None of this
              information is associated with you as an individual.
            </p><br/>

            <p>
              <strong>
                <a id=""></a>How Can You Edit or Delete Your Personal Information?
              </strong>
            </p>

            <p>
              If you have an entry-submission account on one of our websites,
              you may login to that account at any time, access your Profile and
              make and save the changes you wish. If you wish to delete your
              entry-submission account and all of the information associated
              with it, you may do that as well after logging into your account.
              You may also email any changes you wish to make, or your request
              for account deletion, to{" "}
              <a href="mailto:help@stevieawards.com?Subject=Changes to my entry account">
                help@stevieawards.com
              </a><br/>
              .
            </p>

            <p>
              If you have a judging account on one of our websites, you may
              login to that account at any time, access your Profile and make
              and save the changes you wish. If you wish to delete your judging
              account and all of the information associated with it, you may do
              that as well after logging into your account. You may also email
              any changes you wish to make, or your request for account
              deletion, to{" "}
              <a href="mailto:help+judging@stevieawards.com?Subject=Changes to my judging account">
                help+judging@stevieawards.com
              </a>
              .
            </p><br/>

            <p>
              If you subscribed to one or more of our email lists, by filling
              out one or more entry-kit-request or newsletter-subscription forms
              on one or more of our websites, you may unsubscribe from any or
              all of those lists by clicking the unsubscribe link at the bottom
              of any email message served to subscribers of said list. You may
              also access your email-list profile through that link and delete
              all of your information from our subscription lists, if you
              choose. Your may also email your request for deletion of your
              information from our email lists to{" "}
              <a href="mailto:help@stevieawards.com?Subject=Deletion from Stevie Awards email list(s)">
                help@stevieawards.com
              </a>
              .
            </p><br/>

            <p>
              If you subscribed to one or more of the
              entry-deadline-reminder-by-text-message services of our awards
              programs, you may unsubscribe from any or all of those&nbsp;by
              clicking the unsubscribe link in any text message sent to
              subscribers of said list. You&nbsp;may also email your request for
              deletion of your information from our text-message lists to{" "}
              <a href="mailto:help@stevieawards.com?Subject=Deletion from Stevie Awards email list(s)">
                help@stevieawards.com
              </a>
              .
            </p><br/>

            <p>
              Broadly speaking, we use personal information for purposes of
              administering our awards programs, providing customer service and
              making available other products and services to our customers and
              prospective customers. Occasionally, we may also use the
              information we collect to notify you about important changes to
              our websites, new services and special offers we think you will
              find valuable. The lists used to send you product and service
              offers are developed and managed under our traditional corporate
              standards designed to safeguard the security and privacy of our
              customers’ personal information. As a customer, you will be given
              the opportunity to opt out of receiving these offers at any time
            </p><br/>

            <p>
              We also do not market to, or knowingly collect personal
              information from, children under the age of 13 on our websites.
            </p><br/>

            <p>
              We may use vendors to carry out certain functions in marketing and
              delivering services to you (such as processing nominations and
              sending you nomination fulfillment information), and so share
              online data with those vendors to the extent necessary for them to
              perform their work. Of course, such partners and vendors must use
              that personally-identifiable information only for the purpose(s)
              for which it was shared and protect it from any further use. We
              also share such information with other industry and credit-related
              organizations to improve operations and to protect against fraud,
              security and credit risks.
            </p><br/>

            <p>
              We also provide personally-identifiable online information in
              response to subpoenas and other legal demands, and where we
              believe that disclosing this information is necessary to identify,
              contact or bring legal action against individuals who may be
              endangering public safety or interfering with our property or
              services, or with our customers' or others' use of them.
            </p><br/>

            <p>
              <strong>
                <a id="Cookies"></a>What Are Cookies, and How Do We Use Them?
              </strong>
            </p><br/>

            <p>
              Cookies are a feature of web browser software that allows web
              servers to recognize the computer used to access a website.
              Cookies are small pieces of data that are stored by a user’s web
              browser on the user’s hard drive. Cookies can remember what
              information a user accesses on one web page to simplify subsequent
              interactions with that website by the same user or to use the
              information to streamline the user’s transactions on related web
              pages. This makes it easier for a user to move from web page to
              web page and to complete commercial transactions over the
              Internet. Cookies should make your online experience easier and
              more personalized.
            </p><br/>

            <p>
              <strong>
                How Do We Use Information We Collect from Cookies?
              </strong>
            </p><br/>

            <p>
              We use website browser software tools such as cookies and web
              server logs to gather information about our website users’
              browsing activities, in order to constantly improve our websites
              and better serve our customers. This information assists us to
              design and arrange our web pages in the most user-friendly manner
              and to continually improve our websites to better meet the needs
              of our customers and prospective customers.
            </p><br/>

            <p>
              Cookies help us collect important business and technical
              statistics. The information in the cookies lets us trace the paths
              followed by users to our websites as they move from one page to
              another. Web server logs allow us to count how many people visit
              our websites and evaluate our sites' visitor capacity. We do not
              use these technologies to capture your individual email address or
              any personally identifying information about you.
            </p><br/>

            <p>
              <a id="Retention"></a>
              <strong>Data Retention and Erasure</strong>
            </p><br/>

            <p>
              Information we collect may be retained for as long as needed to
              fulfill legitimate business purposes, or for a period of time
              specifically required or allowed by applicable regulation or laws.
              If you no longer want us to use your information to provide
              services to you, you can withdraw your consent or request that we
              erase your information by contacting{" "}
              <a href="mailto:help@stevieawards.com">help@stevieawards.com</a>.
              Please note that if you request erasure of your information, we
              may retain some of your information as necessary for our
              legitimate business interests, such as fraud detection and
              prevention, and to comply with our legal obligations. We will
              respond to your request to exercise these rights within a
              reasonable time (and in all cases within 30 days of receiving a
              request).
            </p><br/>

            <p>
              We do not store your credit card information on our servers and
              only use such information to complete your transactions on our
              websites. All credit card transactions are processed by our third
              party payment processors, including M&amp;T Bank, Paypal, Stripe
              and WePay.
            </p><br/>

            <h4 id="security">
              <a id="Notice"></a>Notice of Breach of Security
            </h4>

            <p>
              If a security breach causes an unauthorized intrusion into our
              system that materially affects your information, then we will
              notify you as soon as possible and later report the action we took
              in response.
            </p><br/>

            <h4 id="track">
              <a id="DoNotTrack"></a>Do-Not-Track Procedures
            </h4>

            <p>
              Note that any Do-Not-Track instructions from your browser or
              similar mechanism will not affect our collection of the data that
              you voluntarily provide to us through such means as browsing our
              websites, signing up for any of our services, or entering personal
              information on our websites.
            </p><br/>

            <p>
              <strong>
                <a id="Protect"></a>How Do We Protect Your Information?
              </strong>
            </p><br/>

            <p>
              <strong>Information Security</strong>. We utilize
              encryption/security software to safeguard the confidentiality of
              personal information we collect from unauthorized access or
              disclosure and accidental loss, alteration or destruction.
            </p><br/>

            <p>
              <strong>Evaluation of Information Protection Practices.</strong>{" "}
              Periodically, our operations and business practices are reviewed
              for compliance with corporate policies and procedures governing
              the security, confidentiality and quality of our information.
            </p><br/>

            <p>
              <strong>Employee Access, Training and Expectations.</strong> Our
              corporate values, ethical standards, policies and practices are
              committed to the protection of customer information. In general,
              our business practices limit employee access to confidential
              information, and limit the use and disclosure of such information
              to authorized persons, processes and transactions.
            </p><br/>

            <p>
              <strong>
                <a id="Disclose"></a>Do We Disclose Information to Outside Parties?
              </strong>
            </p><br/>

            <p>
              We may provide aggregate information about our customers, sales,
              website traffic patterns and related website information to our
              sponsors or reputable third parties, but this information will not
              include personally identifying data, except as otherwise provided
              in this Privacy Policy.
            </p><br/>

            <p>
              <strong>
                What About Legally Compelled Disclosure of Information?
              </strong>
            </p><br/>

            <p>
              We may disclose information when legally compelled to do so, in
              other words, when we, in good faith, believe that the law requires
              it or for the protection of our legal rights.
            </p><br/>

            <p>
              <strong>What About Other Websites Linked to Our Websites?</strong>
            </p><br/>

            <p>
              We are not responsible for the practices employed by websites
              linked to or from one or more of our websites nor the information
              or content contained therein. Often links to other websites are
              provided solely as pointers to information on topics that may be
              useful to the users of our websites.
            </p><br/>

            <p>
              Please remember that when you use a link to go from one of our
              websites to another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including websites which have a link on one of our websites, is
              subject to that website's own rules and policies. Please read over
              those rules and policies before proceeding.
            </p><br/>

            <p>
              <strong>
                <a id="Changes"></a>Changes and Updates to this Policy
              </strong>
            </p><br/>

            <p>
              Please revisit this page periodically to stay aware of any changes
              to this Policy, which we may update from time to time. If we
              modify the Policy, we will make it available through our websites,
              and indicate the date of the latest revision, and will comply with
              applicable law. Your continued use of our websites after the
              revised Policy has become effective indicates that you have read,
              understood and agreed to the current version of the Policy.
            </p><br/>

            <p>
              <strong>
                <a id=""></a>Your Consent
              </strong>
            </p><br/>

            <p>
              By using our Website you consent to our collection and use of your
              personal information as described in this Privacy Policy. If we
              change our privacy policies and procedures, we will post those
              changes on our Website to keep you aware of what information we
              collect, how we use it and under what circumstances we may
              disclose it.
            </p><br/>

            <p>
              <strong>
                <a id="Contact"></a>How to Contact Us
              </strong>
            </p><br/>

            <p>
              Please contact us with any questions, comments, concerns or
              complaints about this Policy, your Personal Data, our use and
              disclosure practices, or your consent choices by email at{" "}
              <a href="mailto:help@stevieawards.com">help@stevieawards.com</a>.
            </p><br/>
          </div>
        </div>
      </div>
    </div>
  );
};
