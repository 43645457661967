import { FaceMatchingTemplate } from "../templates/verification/FaceMatching";
import { VerificationStepType } from "../types/verification/steps";
import { VideoLivenessTemplate } from "../templates/verification/VideoLiveness";
import { useState } from "react";

export const HandlePhotoAndVideoMatchingMolecule = ({
  handleStepChange,
  idCardFile,
}: {
  handleStepChange: (step: keyof VerificationStepType) => void;
  idCardFile: File | null;
}) => {
  const [
    currentPhotoAndVideoMatchingStep,
    setCurrentPhotoAndVideoMatchingStep,
  ] = useState<number>(0);

  function handleNextStep() {
    setCurrentPhotoAndVideoMatchingStep((prevState) => prevState + 1);
  }

  function handlePreviousStep() {
    if (currentPhotoAndVideoMatchingStep > 0) {
      setCurrentPhotoAndVideoMatchingStep((prevState) => prevState - 1);
    }
  }

  if (currentPhotoAndVideoMatchingStep === 0) {
    return (
      <FaceMatchingTemplate
        handleStepChange={handleStepChange}
        handleNextStep={handleNextStep}
        idCardFile={idCardFile}
      />
    );
  }

  if (currentPhotoAndVideoMatchingStep === 1) {
    return (
      <VideoLivenessTemplate
        handleStepChange={handleStepChange}
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
      />
    );
  }

  return null;
};
