import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { BASEURL } from "../constants";

export const UploadFileInputAtom = ({
  placeholder,
  name,
  accept,
  handlePreview,
}: {
  placeholder: string;
  name: string;
  accept: string;
  handlePreview: (name: string, event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className=" flex justify-center items-center">
      <input
        type="file"
        name={name}
        id={name}
        accept={accept}
        className="hidden "
        onChange={(e) => handlePreview(name, e)}
      />
      <label
        htmlFor={name}
        className="btnStevie inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
      >
        {placeholder}
      </label>
    </div>
  );
};

export const UploadPhotoInputAtom = ({
  placeholder,
  name,
  handlePreview,
}: {
  placeholder: string;
  name: string;
  handlePreview: (name: string, dataUrl: string) => void;
}) => {

  return (
    <div className="flex justify-center items-center">
   
      <label
        htmlFor={name}
        className="btnStevie inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
      >
        {placeholder} 
      </label>
    

    </div>
  );
};