import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { NextButtonAtom } from "../../atoms/NextButton";
// import { VerificationStepType } from "../../types/verification/steps";
import { BASEURL } from "../../constants";
import axios from "axios";
import { useRef, useEffect } from "react";
// @ts-ignore
import * as SensityApi from "@sensityai/sensity_api";
// @ts-ignore
import LiveSpoofDetection from "@sensityai/live-spoof-detection";

export const FaceMatchingTemplate = ({
  // @ts-ignore
  handleStepChange,
  // @ts-ignore
  handleNextStep, // Add handleNextStep prop
  // @ts-ignore
  idCardFile, // Add idCardFile prop
}) => {
  const livenessTask = useRef(null);
  const webcam = useRef(null);
  let spoof = useRef(null);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(async () => {
      await monitorSensityLiveness();
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const livenessPhase1 = () => {
    const webcamElement = webcam.current;
    spoof = new LiveSpoofDetection(webcamElement);

    // var SensityApi = spoof.SensityApi;
    var defaultClient = SensityApi.ApiClient.instance;
    var Bearer = defaultClient.authentications["Bearer"];

    Bearer.apiKey =
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2FwaS5zZW5zaXR5LmFpIiwianRpIjoiMDlmOTE2YzMtZjgzYi00ZTgwLTg2ZTQtOTFjMzNmYzkxMWQ2IiwiaWF0IjoxNjgzMjM1MDY5LCJpc3MiOiJTZW5zaXR5Iiwic3ViIjoidmluYXlAdGVzdC5zZW5zaXR5In0.eryf0mSfwieYVqCDv3hjD1OjrQ50Eq6i8fGWeTKOZQU";

    // @ts-ignore
    spoof.startVideo().then((videoStarted: any) => {
      if (videoStarted) {
        console.log("Video started");
      } else {
        console.log("Video failed to load");
      }
    });
  };

  const livenessPhase2 = () => {
    const livenessTaskElement = livenessTask.current;
    console.log("livenessTask=" + livenessTaskElement);
    // @ts-ignore
    spoof.startActiveLiveness(livenessTaskElement);
    console.log("Video started");
  };

  const monitorSensityLiveness = async () => {
    // @ts-ignore
    let t = document.getElementById("livenessTask").innerText;
    const failureText = "Session timed out. Please restart liveness detection.";
    const successText = "Liveness is done";
    if (t.includes(failureText)) {
      await axios.post(
        `${BASEURL}/v1/reports/force_unverify_liveness`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
    } else if (t.includes(successText)) {
      await axios.post(
        `${BASEURL}/v1/reports/force_verify_liveness`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
    }
  };

  const handleNextButtonClick = async () => {
    try {
      //await handleFileUpload();
      handleStepChange("stepFour"); // Change to the appropriate step ID
    } catch (error) {
      console.log("Failed to upload ID card");
      window.alert("Failed to upload ID card. Please try again."); // Show an alert
    }
  };

  return (
    <main className="flex flex-col gap-6 border-2 bg-neutral-100 border-black rounded-lg flex-auto w-full px-6 py-10 mx-auto max-w-7xl lg:px-8">
      <h2 className="text-lg font-bold">Step 3</h2>
      <p>
        In this step we will perform a live verification of your face, to
        confirm that it matches the ID you provided.
      </p>
      <p>
        Click the <b>Start Cam</b> button to open your web cam. Then click the{" "}
        <b>Face Track</b> button to start the process.
      </p>
      <p>
        Follow the on-screen instructions to center your face in the oval, turn
        your face up (toward the ceiling), and to turn your face to the right
        and/or the left.
      </p>
      <p>
        When the screen says “Liveness is done,” click the <b>Next button</b>.
      </p>
      <div className="flex flex-row gap-4 justify-center">
        <button
          className="px-4 py-2 bg-gray-200 border border-black"
          onClick={livenessPhase1}
        >
          1. Start Cam
        </button>
        <button
          className="px-4 py-2 bg-gray-200 border border-black"
          onClick={livenessPhase2}
        >
          2. Face Track
        </button>
      </div>

      <div className="md:w-2/3 w-full rounded-md border-black border-2 mx-auto">
        <div>
          <div>
            <div className="video-container">
              <video ref={webcam} id="webcam" autoPlay playsInline></video>
            </div>
            <div ref={livenessTask} id="livenessTask"></div>
          </div>
        </div>
      </div>

      <div className="md:w-1/3 w-2/3 mx-auto">
        <NextButtonAtom
          text="Next"
          Icon={ChevronRightIcon}
          onClick={handleNextButtonClick}
        />
        {/* <button id="pollButton">
        Button
      </button> */}
      </div>
      <div className="flex justify-center">
        <p
          className="text-sm underline underline-offset-4 hover:text-slate-600 cursor-pointer"
          onClick={() => handleStepChange("stepTwo")}
        >
          Back
        </p>
      </div>
    </main>
  );
};
